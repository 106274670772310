import Nav from 'react-bootstrap/Nav'
import { Navbar } from 'react-bootstrap';
import React from 'react';



const NavBottom = (props) => {

    return (
        <Navbar className="navbar" style={{ backgroundColor:'#3A2C86' }} variant="dark">
            <Navbar.Brand href="/">
                <img src={ process.env.PUBLIC_URL + 'seiu.svg' } alt='logo' style={{padding: '10px'}} />
            </Navbar.Brand>
            <Navbar.Brand>
                <a href="https://teach.seiu1021.org/loginsaml.aspx">
                <img  src={ process.env.PUBLIC_URL + 'dl.jpg' } alt='Digital Learning Benner' style={{padding: '10px', width:'1000px', height:'100px'}} />
                </a>
            </Navbar.Brand>
            <Nav className='mr-auto'></Nav>
            <Nav className='navbar-right'>
                {/* REMOVED 2021.07.12 BY ULYSSES PER BOYAN
                   <Navbar.Brand href="/">
                    Member Resource Center: 1-877-687-1021
                </Navbar.Brand> */}
                
                    {/* <Icon path={ mdiTwitter }
                        title="Twitter"
                        size={iconSize}
                        color={iconColor}
                        style={{margin:'20px'}}
                        />
            
                    <Icon path={ mdiFacebook }
                        title="Facebook"
                        size={iconSize}
                        color={iconColor}
                        style={{margin:'20px'}}
                        />

                    <Icon path={ mdiVimeo }
                        title="Vimeo"
                        size={iconSize}
                        color={iconColor}
                        style={{margin:'20px'}}
                        /> */}
            </Nav>
            <div className="navbar" style={{ backgroundColor:'#3A2C86', marginLeft: '3vw' }} variant="dark">
            </div>
        </Navbar>

    )
}

export default NavBottom;