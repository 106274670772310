import { Nav, NavDropdown } from 'react-bootstrap';
import React from 'react';
import Icon from '@mdi/react'
import { mdiGestureTap, mdiBookOpenPageVariant, mdiAccountBoxMultiple, mdiTools, mdiLightbulbOnOutline, mdiHome, mdiFileChart } from '@mdi/js'
//import { FeedBackLink } from './FeedBackLink'

const iconColor = 'Gray';
const iconSize = '30';
const activeColor = '#3A2C86';
//const hoverColor = '#fae43b';

const navLinkStyle = {
    fontWeight: 'bold',
    color: 'gray',

}

const NavTopSubMenu = () => {

    return (
        <Nav className="row, container-fluid" style={{ height: "24px", marginRight: '120px' }} >
            <Nav.Item>
                <div className="row">
                <Nav.Link href="/" style={{ color: activeColor, fontWeight: 'bold' }}>
                        <Icon path={mdiHome}
                            title="Home"
                            color={activeColor}
                            size={iconSize}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <Nav.Link href="/ReportEmbed" style={{ color: activeColor, fontWeight: 'bold' }}>
                        <Icon path={mdiFileChart}
                            title="Union Intelligence"
                            color={iconColor}
                            size={iconSize}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                            //activeColor={activeColor}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <Nav.Link href="/DashBoardGate" style={{ fontWeight: 'bold' }}>Union Intelligence</Nav.Link>
                        {/* <NavDropdown title="Union Intelligence" id="nav-dropdown" style={{ fontWeight: 'bold' }}>
                            <LinkContainer to="/ReportEmbed">
                            <NavDropdown.Item eventKey="4.1">Dashboard Summary</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Item eventKey="4.4">Membership</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.2">Event Participation</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">New Hires</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">Chapter Strength</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">COPE Participation</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">COPE Dollars</NavDropdown.Item>
                        </NavDropdown> */}
                    </div>
                </div>
            </Nav.Item>
            <Nav.Item>
                <div className="row">
                    <Nav.Link eventKey="link-1" style={navLinkStyle}>

                        <Icon path={mdiBookOpenPageVariant}
                            title="Learning"
                            size={iconSize}
                            color={iconColor}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <NavDropdown title="Digital Learning" id="nav-dropdown" style={{ fontWeight: 'bold' }}>
                            {/* <NavDropdown.Item eventKey="4.1">New Hire</NavDropdown.Item> */}
                            {/* <a className="dropdown-item" href="https://topyxgrace.interactyx.com/loginsaml.aspx" eventKey="4.1" target="_blank">Online Learning</a> */}
                            <a className="dropdown-item" href="https://teach.seiu1021.org/loginsaml.aspx" eventKey="4.1" target="_blank">Online Learning</a>
                        </NavDropdown>
                    </div>
                </div>
            </Nav.Item>
            <Nav.Item>
                <div className="row">
                    <Nav.Link eventKey="link-2" style={navLinkStyle}>

                        <Icon path={mdiGestureTap}
                            title="Events"
                            size={iconSize}
                            color={iconColor}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <NavDropdown title="Action/Training" id="nav-dropdown" style={{ fontWeight: 'bold' }}>
                            {/* <LinkContainer to="https://seiu1021.interactyx.com/">
                            <NavDropdown.Item eventKey="4.1">Event Registration</NavDropdown.Item>
                            </LinkContainer> */}
                            {/* <a className="dropdown-item" href="https://www.seiu1021.org/news-events" eventKey="4.1" target="_blank">Event Registration</a> */}
                            <a className="dropdown-item" href="/rsvpeventspage" eventKey="4.1" target="_blank">Event Registration</a>
                            {/* <a className="dropdown-item" href="https://www.seiu1021.org/training" eventKey="4.4" target="_blank">Training</a> */}
                            {/* <NavDropdown.Item eventKey="4.4">Virtual Phone Link</NavDropdown.Item> */}
                        </NavDropdown>
                    </div>
                </div>
            </Nav.Item>
            <Nav.Item>
                <div className="row">
                    <Nav.Link eventKey="disabled" style={navLinkStyle}>

                        <Icon path={mdiAccountBoxMultiple}
                            title="Contracts"
                            size={iconSize}
                            color={iconColor}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <NavDropdown title="Contracts/Bylaws" id="nav-dropdown" style={{ fontWeight: 'bold' }}>
                            {/* <a className="dropdown-item" href="https://www.seiu1021.org/find-your-chapter-contract" eventKey="4.1" target="_blank">My Contract and Bylaws (coming soon)</a> */}
                            <a className="dropdown-item" href="/ContractDocs" eventKey="4.1" target="_blank">My Contract and Bylaws</a>

                            <a className="dropdown-item" href="https://d3jpbvtfqku4tu.cloudfront.net/img/ConstitutionBylaws.pdf" eventKey="4.1" target="_blank" >International Constitution and Bylaws</a>
                            <a className="dropdown-item" href="https://www.seiu1021.org/sites/main/files/file-attachments/seiu1021-bylaws_09-2012.pdf?1595547741" eventKey="4.1" target="_blank">SEIU Local 1021 Bylaws</a>
                            <a className="dropdown-item" href="https://www.seiu1021.org/find-your-chapter-contract" eventKey="4.1" target="_blank">SEIU Local 1021 all contracts</a>
                        </NavDropdown>
                    </div>
                </div>
            </Nav.Item>
            <Nav.Item>
                <div className="row">
                    <Nav.Link eventKey="disabled" style={navLinkStyle}>

                        <Icon path={mdiTools}
                            title="Tool Kits"
                            size={iconSize}
                            color={iconColor}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <NavDropdown title="Tool Kits" id="nav-dropdown" style={{ fontWeight: 'bold' }}>
                            {/* <NavDropdown.Item eventKey="4.1">New Hires</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">TBD</NavDropdown.Item> */}
                            <a className="dropdown-item" href="https://www.seiu1021.org/sites/main/files/file-attachments/chapter_leader_resources_request_manual.pdf" eventKey="4.1" target="_blank">How to request digital resources</a>
                            <a className="dropdown-item" href="https://www.seiu1021.org/sites/main/files/mobile_app_installation_guide.pdf" eventKey="4.1" target="_blank">Mobile App Manual</a>
                            <a className="dropdown-item" href="https://www.seiu1021.org/sites/main/files/union_intelligence_mrc_portal_user_guide.pdf" eventKey="4.1" target="_blank">Union Intelligence Manual</a>
                            <a className="dropdown-item" href="https://vimeo.com/757658689" eventKey="4.1" target="_blank">Portal Video Guide</a>
                            <a className="dropdown-item" href="https://www.seiumb.com/" eventKey="4.1" target="_blank">Member Benefits & Discounts</a>
                        </NavDropdown>
                    </div>
                </div>
            </Nav.Item>
            <Nav.Item>
                <div className="row">
                    <Nav.Link eventKey="disabled" style={navLinkStyle}>

                        <Icon path={mdiLightbulbOnOutline}
                            title="Resources"
                            size={iconSize}
                            color={iconColor}
                            style={{ paddingTop: '2px', maxHeight:'22px' }}
                        />
                    </Nav.Link>
                    <div style={{ marginTop: '2px', maxHeight:'22px' }}>
                        <NavDropdown title="Resources" id="nav-dropdown" style={{ fontWeight: 'bold', marginRight:'25px' }}>
{/*                             <NavDropdown.Item eventKey="4.1">Book Virtual <br />&nbsp;&nbsp; Conference Rooms</NavDropdown.Item>
                            <FeedBackLink />
                            <NavDropdown.Item eventKey="4.4">Download an App</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">Self Help</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">Self Identity Management</NavDropdown.Item> */}
                            <a className="dropdown-item"  href="https://memberlink.seiu1021.org/SEIU1021MemberApplicationStep1.aspx" eventKey="4.1" target="_blank" rel="noopener noreferrer">Membership Application</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}}  href={require("../docs/Member-general-expenses_policy-form_4.19.16.pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer">Member General Expense Policy</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}}  href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_code_of_conduct_ebd-approved_2021-10-23.pdf?1635291070" eventKey="4.1" target="_blank" rel="noopener noreferrer">Code of Conduct</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}}  href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_localwide-code_of_ethics_ebd-adopted_2019-09-29.pdf?1572926574" eventKey="4.1" target="_blank" rel="noopener noreferrer">Code of Ethics Policy</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}}  href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_disputes-between-members_rev_ebd-approved_2014-11-22_1.pdf?1523993873" eventKey="4.1" target="_blank" rel="noopener noreferrer">Policy Disputes Between Members</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}} href={require("../docs/Chapter Authorization to Issue Chapter Funds.pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer">Chapter Authorization to issue Chapter Funds</a>
                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}} href={require("../docs/Chapter Signatory Form[99313].pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer">Chapter Signatory</a>
              
                            <a className="dropdown-item"  href="https://www.callhub.io" eventKey="4.1" target="_blank">Virtual Phonebank</a>
                            <a className="dropdown-item"  href="https://seiu1021.freshdesk.com/widgets/feedback_widget/new" eventKey="4.1" target="_blank" rel="noopener noreferrer">Digital resource request</a>

                            <a className="dropdown-item" style={{whiteSpace:'pre-wrap'}} href={require("../docs/MRC Portal FAQ.pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer">Frequently Asked Questions</a>
                        </NavDropdown>
                    </div>
                </div>
            </Nav.Item>
        </Nav>
    )
}

export default NavTopSubMenu;