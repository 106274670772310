import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'; 
import Table from "./RSVPTableTemplate";
import { Spinner } from 'react-bootstrap';
  
const NODATA = "You are not registered for any events."

export function RSVPRegisteredEvents(props) {

        const [data, setData] = useState(0);
        const [loading, setLoading] = useState(0);

        function unregister(eventRegistrationID) {
            console.log(`Unregistering member ${props.memberID} with eventregid ${eventRegistrationID}`);

            let axiosConfig = {
                headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                }
            };

            const uri = `https://prod-65.westus.logic.azure.com/workflows/4c61cc77ccfc4735aff8774c552fd495/triggers/manual/paths/invoke/${eventRegistrationID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YgojLRCQI2VtV3Xq81g8GO_H-jqupXbfcBfHriH7Uvo`;
            axios.get(uri, {}, axiosConfig)
            .then((response) => {
                //console.log(response);
                alert(`Successfully un-registered you from event`);
            window.location.reload(false);
            }, (error) => {
                console.log(error);
            })
        }

        useEffect(() => {
            setLoading(true);
            (async () => {
                const result = await axios(`https://prod-52.westus.logic.azure.com/workflows/db71493270564ed19d57674acc60cebc/triggers/manual/paths/invoke/${props.memberID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r1RHW-JN767bYHKn8yEoINy0tjTlktwWFSJRJ_sP5EA`)
                .catch(
                    setLoading(false)
                )
                setData(result.data)
            })();
        }, [props.memberID]); // Added empty dependency array




        // Setup Table
        const columns = useMemo(
            () => [
            {

                Header: "Your Registered Events",
                //Columns
                columns: [
                {
                    Header: "Event Name",
                    accessor: "Name"
                },
                {
                    Header: "Start Date",
                    accessor: "StartDateTime"
                    // accessor: d => {
                    //     return moment(d.StartDate)
                    //       .local().format("YYYY-MM-DD hh:mm:ss A")
                    // },
                    // sortType: "datetime",
                    // Cell: row => <span>
                    // {
                    //   moment(row.value).format('MM/DD/YYYY h:mm A')
                    // }
                    // </span>
                },
                {
                    Header: "End Date",
                    accessor: "EndDateTime"
        
                },
                {
                    Header: "Event ID",
                    accessor: "EventID"
                }
                ,
                {
                    Header: "Register",
                    accessor: d => {
                        return <button onClick={() => unregister(d.RegistrationID)} >Unregister</button>
                    }
                }
                ]
            }
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        return (
            <div>
                {
                    data 
                    ?
                    <Table columns={columns} data={data}  />
                    :
                        loading ?
                            <div className="div-loading" style={{"color" : "purple","display" : "flex", "alignItems" : "center", "width" : "100%"}}>
                                <Spinner animation="border" style={{"color" : "purple"}} /><h1>Fetching Registered Events... </h1>
                            </div>

                            :

                            <div className="div-loading" style={{"color" : "purple","display" : "flex", "alignItems" : "center", "width" : "100%"}}>
                                <h1>{NODATA}</h1>
                            </div>
                }
            </div>
        );
}
