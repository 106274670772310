
import React, { Component } from 'react';
import NavBottom from './NavBottom';
import { RSVPUpcomingEvents } from './RSVPUpcomingEvents';
import { RSVPRegisteredEvents } from './RSVPRegisteredEvents';


export class RSVPEventsPage extends Component {
      
      render() {
        return (
          <div>
          <div style={{"display" : "flex", "flexDirection" : "row", "height" : "calc(100vh - 220px)"}}>
            <div style={{"paddingLeft" : "10vw", "width" : "50%", "marginRight" : "5px", "marginTop" : "1vh", "overflow" : "auto"}}>
              <RSVPUpcomingEvents memberID={ this.props.memberID } />
            </div>
            <div style={{"paddingRight" : "10vw", "width" : "50%", "marginLeft" : "5px", "marginTop" : "1vh"}}>
              <RSVPRegisteredEvents memberID={ this.props.memberID } />
              {/* <RSVPRegisteredEvents memberID="156386" /> */}
            </div>
            </div>
            <NavBottom />
            </div>
        );
    }
}
