
import React, { Component } from 'react';
import { Report } from 'powerbi-report-component';
import axios from 'axios'; 


const reportStyle = {
    // style object for report component
    height: 'calc(100vh)',
    // width: '1200px',
    // height: '100%',
    width: '100%',
    //marginTop: '10px'
};

export class Dev extends Component {
    constructor(props) {
        super(props);
        this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc..
      }
      state = {
        embedInfo: "",
        embedAddress: "",
        embedReportID: "",
        embedFunctionErrorMessage: "",
        filterApplied: false
        //memberID: authProvider._accountInfo.account.idToken.extension_MemberID,
      }
      //...
      handleDataSelected = (data) => {
        // will be called when some chart or data element in your report clicked
      }
    
      handleReportLoad = (report) => {
        // will be called when report loads:
        // - scripts and data received from server, visuals are rendered on the browser
        // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    
        this.report = report; // get the object from callback and store it.(optional)

        

        var filter1 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
              table: "VisibilityNew",
              column: "MemberID"
          },
          operator: "In",
          values: ["202895"]
        };
        
        var filter = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
              table: "PersonNew",
              column: "MIJI"
          },
          operator: "In",
          values: ["188528-286594"]
        };
        console.log('filtering after');
        //report.updateFilters(window['powerbi-client'].models.FiltersOperations.Replace, [filter]);
        var me = this;
        report.setFilters([filter]).then(function() {
          report.updateSettings({filterPaneEnabled: false}).then(function(){
            me.setState({filterApplied: true});
          })
        });

        console.log('hidinig filter pane?');
        
        window['report'] = report;

      }
    
      handleReportRender = (report) => {
        // will be called when report renders:
        // - visuals finish rendering
        // - report is fully visible and ready for consumption
    
        this.report = report; // get the object from callback and store it.(optional)
      }
    
      handlePageChange = (data) => {
        // will be called when pages in your report changes
      }
    
      handleTileClicked = (data) => {
        console.log('Data from tile', data);
      }

      componentDidMount() {
        // axios.all([
        //     axios.get('https://seiu-twr-api.azurewebsites.net.membershipchartkpi'),
        //     axios.get('')
        // ])
        // axios.get('https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/HttpTrigger1?code=KXUBRwiSZEJA4bs9nA3ek8BuG7Gv2od1bheXfzNiHA6KoMQBxdwZxw==').then(response => response.data)
        // .then((data) => {this.setState({ embedInfo: data.EmbedToken})
        // })
        //var effectiveUser = "&effectiveUser=" + authProvider._accountInfo.account.idToken.emails[0];
        //var effectiveUser = "&effectiveUser=" + authProvider._accountInfo.account.idToken.extension_MemberID;
        var effectiveUser = "&effectiveUser=" + this.props.memberID;
        //var effectiveUser = "&effectiveUser=156386";
        //var effectiveUser = "&effectiveUser=122165";
        //var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/PBIEmbedEffectiveIdentity?code=Az7zv1Vud2MHnpfFLhFW4FHPaY8ypkP1/nAdIZBGkRPqOpJeqjqRpA==" + effectiveUser + effectiveRole;
        //var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/HttpTrigger1?code=KXUBRwiSZEJA4bs9nA3ek8BuG7Gv2od1bheXfzNiHA6KoMQBxdwZxw==";
        //var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/PBIEmbedAzureChapterEmbed?code=xFkplD0oGixajtatS3jOcZPIsd5z7EpX8uuC3gzjIsVBBnhdwaUaxw==" + effectiveUser;
        //var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/PBIEmbedMainMemberID?code=h1/OdK9zxuYFJ8HQmSbvnWgAx0aP8lwaPqTvqlK5wsxeHUnkCD1lZg==" + effectiveUser;

        var params = new URLSearchParams(window.location.search);

        // Convert the parameters to an object
        var paramsObj = {};
        for (var [key, value] of params.entries()) {
          paramsObj[key] = value;
        }

        var reportID = paramsObj.reportid ? '&reportid=' + paramsObj.reportid : '';
        var groupID = paramsObj.groupid ? '&groupid=' + paramsObj.groupid : '';
        var filter = paramsObj.addquery ? '&filter=' + paramsObj.filter : '';

        console.log('this.state.filterApplied', this.state.filterApplied);

        //var urlStem = "&groupid=3027d554-2bc5-40b2-94ad-c75300b22cfc&reportid=05b6b0a2-26a3-49f5-9cd6-d343deb57628"
        var urlStem = groupID + reportID;

        //dev
        //var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/PBIEmbedDev?code=PMbeKa1MZhPfCuDGSZMc9e2yf2WVgUhOv2MvpHjkjPugvPwEsctojQ%3D%3D" + effectiveUser;
        var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/PBIEmbedDevQuery?code=ktO8DfccaAWl72gn4mVaEXT7hbbNZxQiU00BPQpp9bEz91lSvRzQig==" + effectiveUser + urlStem;
        
        axios.get(queryAddress).then(response => response.data)
        .then((data) => {this.setState({ embedInfo: data.EmbedToken, embedAddress: data.EmbedUrl, embedReportID: data.ReportId, embedFunctionErrorMessage: data.errorMessage })
        })


      }

      /*
                      <div className="dev-coverup" style="position:fixed;background-color:white;width:100%;height:100%;color:black;text-align:center; padding-top:20px;">

*/


      render() {
        return (
          <div>
            <div className="dev-coverup-wrapper">
              { this.state.filterApplied == false && (
                <div className="dev-coverup" style={{"position":"fixed", "backgroundColor":"white", "width":"100%", "height":"100%", "color":"black", "textAlign":"center", "paddingTop":"20px"}}>
                  Loading...
                </div>              
              )}
            </div>
            <div className="dev-error-message">
                { this.state.embedFunctionErrorMessage && (<div style={{marginTop: '24px'}}> {this.state.embedFunctionErrorMessage}</div>) }
            </div>
            <div className="powerbi-frame" style={{"height" : "calc(100vh)"}}>
              {
                this.state.embedInfo ?
                  <Report 
                      embedType="report"
                      tokenType="Embed"
                      accessToken={this.state.embedInfo}
                      embedUrl={this.state.embedAddress}
                      embedId={this.state.embedReportID}
                      permissions="All"
                      style={reportStyle}
                      onLoad={this.handleReportLoad}
                      onRender={this.handleReportRender} 
                      />
                      :
                      <div style={{"color" : "purple","display" : "flex", "alignItems" : "center","height" : "calc(100vh - 220px)", "width" : "100%", "margin" : "0 auto", "textAlign" : "center"}}><h1 style={{"marginLeft" : "auto", "marginRight" : "auto"}}>Loading Dashboard Data...</h1></div>
              }
            </div>
            {/* <NavBottom /> */}
            </div>
        );
    }
}
