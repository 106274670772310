import React from 'react';


const GoogleTranslate = (props) => {

  
    return (
      <div id="google_translate_element" style={{backgroundColor: '#DDD',textAlign: 'center','padding': '3px', 'border-radius': '3px'}}></div>
    )
}

export default GoogleTranslate;