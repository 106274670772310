import {Navbar, Nav, Button} from 'react-bootstrap';
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
//import Icon from '@mdi/react'
//import { mdiViewDashboardVariant  } from '@mdi/js'
import NavTopSubMenu from './NavTopSubMenu';
import { ReportEmbed } from './ReportEmbed';
import { RSVPUpcomingEvents } from './RSVPUpcomingEvents'; 
import { RSVPRegisteredEvents } from './RSVPRegisteredEvents'; 
import { RSVPEventsPage } from './RSVPEventsPage';

//import { authProvider } from '../auth/authProvider';
// REMOVED 2021.07.12 By Ulysses per Boyan -- import { FeedBack } from './FeedBack';
import { ReportEmbedImpersonate } from "./ReportEmbedImpersonate";

import { Home } from "./Home"
import { NewHome } from "./NewHome"
import { NewHome2 } from "./NewHome2"   
import { Dev } from "./Dev"   
import { DashBoardGate } from "./DashBoardGate"
import { ContractDocs } from "./ContractDocs"
//import  Barcode  from "./Barcode";
import GoogleTranslate from "./GoogleTranslate"



const NavTop = (props) => {

    var { memberID, displayName, logout, jwt, jwtTest } = props;
    console.log( memberID);

    // impersonation for DEVELOPERS ONLY, this should always be commented out
    // memberID = 619539;

    return (
        <div>
        <Navbar className="navbar" style={{ backgroundColor:'#3A2C86'}} variant="dark">
            <Navbar.Brand href="/">
                <img src={ process.env.PUBLIC_URL + 'seiu.svg' } alt='logo' style={{padding: '10px'}} />

            </Navbar.Brand>
            <Nav className='mr-auto'>
                <Navbar.Brand href="/">
                    Member Resource Center: 1-877-687-1021
                </Navbar.Brand>
                    {/* <NavDropdown title="Action" id="nav-dropdown"  style={{fontWeight:'bold'}}>
                        <NavDropdown.Item eventKey="4.1">Sign In</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2">Sign Out</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.4">View My Profile</NavDropdown.Item>
                    </NavDropdown> */}
            </Nav>
            <Navbar.Brand href="/">
                Welcome { displayName } To <br></br>your MRC Dashboard
             </Navbar.Brand>
            <GoogleTranslate />
            <Navbar.Brand href="/">
            </Navbar.Brand>
            {/* {
                showSearch &&
                    <Form inline>
                        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                         <Button variant="outline-info">Search</Button>
                    </Form>
            } */}
            {/* <FeedBack /> */}
            <Button variant="outline-danger" className="mx-2" onClick={ logout } style={{backgroundColor: '#EEE'}}>Logout</Button>


        </Navbar>
        <Router>
        <Navbar className="navbar" variant="light" style={{ backgroundColor:'LightGray'}}>
            <NavTopSubMenu />
        </Navbar>
        <Switch>
            {/* Only for testing */}
            <Route path="/RSVPUpcomingEvents" >
                <RSVPUpcomingEvents memberID={ memberID } />
            </Route>
            <Route path="/DashBoardGate" >
                <DashBoardGate memberID={ memberID } />
            </Route>
            <Route path="/ContractDocs" >
                <ContractDocs memberID={ memberID } />
            </Route>
            <Route path="/RSVPRegisteredEvents" >
                <RSVPRegisteredEvents memberID={ memberID } />
            </Route>
            <Route path="/RSVPEventsPage" >
                <RSVPEventsPage memberID={ memberID } />
            </Route>            
            <Route exact path="/NewHome">
                <NewHome  />
            </Route>
            <Route exact path="/NewHome2">
                <NewHome2 />
            </Route>
            <Route exact path="/Dev">
                <Dev memberID={ memberID } jwt={ jwt } jwtTest={ jwtTest } />
            </Route>
            <Route path="/ReportEmbed">
                <ReportEmbed  memberID={ memberID }  />
            </Route>
            <Route path="/Impersonate">
                <ReportEmbedImpersonate />
            </Route>
            <Route path="/forgot" component={() => {
                window.location.href = "";
                return null;
                }}
            />
{/*             <Route path="/Barcode">
                <Barcode />
            </Route> */}
            <Route path="/">
                <Home memberID={ memberID } />
            </Route>
        </Switch>
        </Router>
        </div>

    )
}

export default NavTop;