
import React, { Component } from 'react';
import { Report } from 'powerbi-report-component';
import axios from 'axios'; 
import NavBottom from './NavBottom';
import { RSVPUpcomingEventsMain } from './RSVPUpcomingEventsMain';
import { Spinner } from 'react-bootstrap';


const reportStyle = {
    // style object for report component
    height: '100%'
    //height: 'calc(100vh - 220px)',
    //display : 'flex',
    //marginTop: '10px'
};

const extraSettings = {
  filterPaneEnabled: false, //true
  navContentPaneEnabled: false, //true
}

export class Home extends Component {

    constructor(props) {
        super(props);
        this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc..
      }

      state = {
        embedInfo: "",
        embedAddress: "",
        embedReportID: "",
        //memberID: authProvider._accountInfo.account.idToken.extension_MemberID,
      }
      //...
      handleDataSelected = (data) => {
        // will be called when some chart or data element in your report clicked
      }
    
      handleReportLoad = (report) => {
        // will be called when report loads:
        // - scripts and data received from server, visuals are rendered on the browser
        // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    
        this.report = report; // get the object from callback and store it.(optional)
      }
    
      handleReportRender = (report) => {
        // will be called when report renders:
        // - visuals finish rendering
        // - report is fully visible and ready for consumption
    
        this.report = report; // get the object from callback and store it.(optional)
      }
    
      handlePageChange = (data) => {
        // will be called when pages in your report changes
      }
    
      handleTileClicked = (data) => {
        console.log('Data from tile', data);
      }


      componentDidMount() {
        var effectiveUser = "&effectiveUser=" + this.props.memberID;
        //var effectiveUser = "&effectiveUser=156386";
        //var effectiveUser = "&effectiveUser=122165";
        
        var queryAddress = "https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/MRCPRDHomeKPIEmbed?code=AzxCyhFfaWLcSddT3H5zsxeGag8aR3e9R/ctdnWEwe678WRJbGDXVw==" + effectiveUser;
        console.log(this.props.memberID);

        axios.get(queryAddress).then(response => response.data)
        .then((data) => {this.setState({ embedInfo: data.EmbedToken, embedAddress: data.EmbedUrl, embedReportID: data.ReportId })
        })       
      }



      render() {

        return (
          <div>
          <div style={{"display" : "flex", "flexDirection" : "row", "height" : "calc(100vh - 220px)"}}>
            <div className="powerbi-frame" style={{"width" : "100%", "marginLeft" : "10vw", "marginTop" : "1vh"}}>

                { this.state.embedInfo 
                ? <Report 
                    embedType="report"
                    tokenType="Embed"
                    accessToken={this.state.embedInfo}
                    embedUrl={this.state.embedAddress}
                    embedId={this.state.embedReportID}
                    extraSettings={extraSettings}
                    permissions="View"
                    style={reportStyle}
                    />
                : 
                <div style={{"color" : "purple","display" : "flex", "alignItems" : "center", "height" : "calc(100vh - 220px)", "width" : "100%", "border" : "1px solid Purple", "padding" : "10px", "borderRadius" : "25px"}}><Spinner animation="border" style={{"color" : "purple", "marginLeft" : "auto", "marginRight" : "0px"}} /><h1 style={{"marginLeft" : "0px", "marginRight" : "auto"}}>Loading Dashboard Data... </h1></div>
                }
            </div>
            <div style={{"marginRight" : "10vw", "marginTop" : "1vh"}}>
              <RSVPUpcomingEventsMain memberID={ this.props.memberID } />
            </div>
            </div>
            <NavBottom />
            </div>
        );
    }
}

