import React from "react";
import { useTable, useSortBy } from "react-table"
import './TableStyle.css';

export default function RSVPTableTemplate({ columns, data }) {
    // Use hooks to get data into the table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [{ id: 'StartDate', desc: false }]
        }
    },
    useSortBy
    );

    return (
      <div style={{"border" : "1px solid Purple", "padding" : "10px", "borderRadius" : "25px", "height" : "calc(100vh - 300px)"}}>
        <div style={{"overflow" : "scroll", "height" : "calc(100vh - 340px)"}}>
        <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
{/*                     {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''} */}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )}

        </tbody>
        </table>
        </div>
        </div>
      );
}