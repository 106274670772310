import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from "react-bootstrap";

export function RSVPUpcomingEvents(props) {
  const [allEvents, setAllEvents] = useState(0);
  const [data, setData] = useState(0);
  const [locations, setLocations] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  function register(eventID) {
    console.log(`Registering member ${props.memberID} for event ${eventID}`);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    let uri = `https://prod-08.westus.logic.azure.com/workflows/335a123bf43d4895b0a4871a6da05928/triggers/manual/paths/invoke/${props.memberID}?EventID=${eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jfLaO_vo-jSDVkqGiHkaUDLRJe7Kh4GACI94WVZr9CU`;
    //let uri = `https://prod-08.westus.logic.azure.com/workflows/335a123bf43d4895b0a4871a6da05928/triggers/manual/paths/invoke/156386?EventID=${eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jfLaO_vo-jSDVkqGiHkaUDLRJe7Kh4GACI94WVZr9CU`;
    axios.get(uri, {}, axiosConfig).then(
      (response) => {
        //console.log(response);
        alert(`Registration successful for ${eventID}`);
        window.location.reload(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // Using useEffect to call the API once mounted then set the state
  useEffect(() => {
    (async () => {

        let queryParams = new URLSearchParams(window.location.search);
        const locationNameFromQueryString = queryParams.get("location");

        if (!allEvents) { 
            // Events are not in state - query them
            const result = await axios(
                "https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk"
            );

            setAllEvents(result.data); // The unfiltered raw list of all events (Not displayed)
            setData(result.data); // List of potentially filtered data that is displayed

            // Get a list of all locations
            let _locations = [];
            result.data.forEach((element) => {
              if (element.LocationName === null) {
                // ignore - left for readability
              } else {
                if (_locations.indexOf(element.LocationName) === -1) {
                  _locations.push(element.LocationName);
                }
              }
            });
      
            // Persist the locations to state
            setLocations(_locations);
        }
        else { 
            // The events and locations are already in state
            let effectiveSelectedLocation = null;

            locationNameFromQueryString ? effectiveSelectedLocation = locationNameFromQueryString : effectiveSelectedLocation = selectedLocation;

            // The user selected a value from the locations drop down
            if (effectiveSelectedLocation && effectiveSelectedLocation !== "See all locations") {
                let _data = [];
                allEvents.forEach((element) => {
                if (element.LocationName === effectiveSelectedLocation) {
                    _data.push(element);
                }
                });
                setData(_data);
                setSelectedLocation(effectiveSelectedLocation);
            } else {
                // There's no location selected - reset the all events to the allEvents
                setData(allEvents);
            }
        }

    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, locations]); // Refresh when the user selects a location from the drop down OR when the initial setLocations  is run

  // Setup Table
  const columns = useMemo(
    () => [
      {
        //Table Header - Upcoming Events
        Header: "Upcoming Events",
        //Columns
        columns: [
          {
            Header: "Event Name",
            accessor: "Name",
          },
          {
            Header: "Start Date",
            accessor: "StartDate",
            //sortType: "datetime",
            //Cell: ( { value }) => { return format(new Date(value), 'MM/dd/yyyy h:mm a')},
            sortType: (a, b) => {
              var a1 = new Date(a.values.StartDate);
              var b1 = new Date(b.values.StartDate);
              //console.log(a1);
              //console.log(b1);
              if (a1 > b1) return 1;
              else if (a1 < b1) return -1;
              else return 0;
            },
          },
          {
            Header: "End Date",
            accessor: "EndDate",
            sortType: (a, b) => {
              var a1 = new Date(a.values.EndDate);
              var b1 = new Date(b.values.EndDate);
              //console.log(a1);
              //console.log(b1);
              if (a1 > b1) return 1;
              else if (a1 < b1) return -1;
              else return 0;
            },
          },
          {
            Header: "Event ID",
            accessor: "EventID",
          },
          {
            Header: "Location",
            accessor: "LocationName",
          },

          {
            Header: "Register",
            accessor: (d) => {
              return (
                <Button
                  className="mx-2 btn-events"
                  onClick={() => register(d.EventID)}
                >
                  Register
                </Button>
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

/*   const handleSelectedLocationChange = (e) => {
    e.preventDefault();
    setSelectedLocation(e.target.value);
  }; */

  
  return (
    <div>
      {data ? (
        <div>
          {/*  Removed 20221028 Ulysses
           <select
            style={{
              width: "350px",
              height: "40px",
              fontSize: "15px",
              fontWeight: "bold",
              margin: "5px",
            }}
            variant="info"
            onChange={handleSelectedLocationChange}
          >
            {selectedLocation ? (
              <option>See all locations</option>
            ) : (
              <option>Choose a location</option>
            )}
            {locations && (
              locations.map((loc) => <option key={loc} value={loc===selectedLocation}   >{loc}</option>)
            )}
          </select> */}
          <Table columns={columns} data={data} />
        </div>
      ) : (
        <div
          className="div-loading"
          style={{
            color: "purple",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Spinner animation="border" style={{ color: "purple" }} />
          <h1>Fetching Upcoming Events... </h1>
        </div>
      )}
    </div>
  );
}

