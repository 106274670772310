import React from 'react';
import './App.css';
import { msalConfig, loginRequest } from './auth/authConfig';
import { b2cPolicies } from './auth/policies';
import * as Msal from 'msal';
import NavTop from './Components/NavTop';
//import Barcode from './Components/Barcode';


const myMSALObj = new Msal.UserAgentApplication(msalConfig);
myMSALObj.handleRedirectCallback(authRedirectCallBack);
let accessToken;
let authToken;

function authRedirectCallBack(error, response) {
  // Error handling
  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      try {
        // Password reset policy/authority
        myMSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
      } catch(err) {
        console.log(err);
      }
    }
  } else {
    // We need to reject id tokens that were not issued with the default sign-in policy.
    // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
    // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    // 20200804 UJL - Bug in the demo from msft doens't take into account lower/upper case characters in the policy names
    if (response.tokenType === "id_token" && response.idToken.claims['acr'].toLowerCase() !== b2cPolicies.names.signUpSignIn.toLowerCase() )   {
      myMSALObj.logout();
      window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
    } else if (response.tokenType === "id_token" && response.idToken.claims['acr'].toLowerCase() === b2cPolicies.names.signUpSignIn.toLowerCase() ) {
      console.log("id_token acquired at: " + new Date().toString());  

      if (myMSALObj.getAccount()) {
        //updateUI();
        console.log('Received a response');
      }

    } else if (response.tokenType === "access_token") {
        console.log("access_token acquired at: " + new Date().toString());
        accessToken = response.accessToken;
        //logMessage("Request made to Web API:");
        if (accessToken) {
          try {
            //callApiWithAccessToken(apiConfig.webApi, accessToken);
            console.log('Access token received: ' + accessToken)
          } catch (err) {
            console.log(err);
          }
        }
    } else {
        console.log("Token type is: " + response.tokenType);
    }
  }
}

export function signIn() {
  myMSALObj.loginRedirect(loginRequest);
}  

// sign-out the user
export function logout() {
  // Removes all sessions, need to call AAD endpoint to do full logout
  myMSALObj.logout();
}

// main method to get token with redirect flow
export function getTokenRedirect(request) {
  return myMSALObj.acquireTokenSilent(request)
    .then((response) => {
      if (response.accessToken) {
        accessToken = response.accessToken;
        //logMessage("Request made to Web API:");
        console.log('hit in api gettokenrequest')
        if (accessToken) {
          try {
            //callApiWithAccessToken(apiConfig.webApi, accessToken);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }).catch(error => {
      console.log("Silent token acquisition fails. Acquiring token using redirect");
      console.log(error);
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenRedirect(request);
    });
}


function App() {


  let authAccount = myMSALObj.getAccount();

/*   const request = {
    scopes: ["User.Read"]
  }; */

  /*
  myMSALObj.acquireTokenSilent(request).then(tokenResponse => {
    console.log('acquireTokenSilent', tokenResponse);
    authToken = tokenResponse;
  }).catch(err => {
    console.log('acquireTokenSilent catch', err);
  });
  //*/
  //*


  /*
  myMSALObj.acquireTokenSilent(request)
  .then(tokenResponse => {
    console.log('acquireTokenSilent', tokenResponse);
    authToken = tokenResponse;
  })
  .catch(err => {
    console.log('acquireTokenSilent catch', err);
    if (err.name === "ClientAuthError" && err.errorCode === "token_renewal_error") {
      myMSALObj.acquireTokenPopup(request)
        .then(tokenResponse => {
          console.log('acquireTokenPopup', tokenResponse);
          authToken = tokenResponse;
        })
        .catch(err => {
          console.log('acquireTokenPopup catch', err);
        });
    }
  });
  */



  
//*/

  console.log(myMSALObj);

  if (!authAccount)
  {
    if (window.location.href.includes('barcode')){
      //return(<Barcode/>);
      return(<div>Barcode loading unauthenticated --- add this...</div>)
    }
    else {
      signIn(loginRequest);
      return(<div>Loading...</div>);
    }
  }
  else{
    // continue on to show the app
  }

  const account = myMSALObj.account; //myMSALObj.account.idToken['jobTitle'];
  let memberID = account.idToken.jobTitle;
  const displayName = account.idToken.name;
  let email = '';

  // Adding the signInNames.emailAddresses is a midstream change where users may still be logged in but not have this claims
  // Therefore, let's try to prevent an undefined reference error
  try {
    email = account.idToken["signInNames.emailAddress"].toString();
  }
  catch {
    // Users that don't yet have their claims updated (but will once they re-authenticate)
  }


  //jwt={authToken}

  if (!myMSALObj.account){
    return(<div>Loading...</div>)
  }
  else{
    return (
        <div>
            <NavTop account={ account } jwt={ authToken } jwtTest={ 'test55' } memberID={ memberID } displayName={ displayName } logout={ logout } email={ email } /> 
            
            {/* 2 Hidden divs to hold the email and display names that are picked up by the FreshDesk and FreshChat widgets */}
            <div style={{ visibility:'hidden', height:0 }} id="Email">{email}</div>
            <div style={{ visibility:'hidden', height:0 }} id="DisplayName">{displayName}</div>
            <div style={{ visibility:'hidden', height:0 }} id="MemberID">{memberID}</div>
        </div>
    );
  }
}

export default App;

