
import React, { Component } from 'react';
import NavBottom from './NavBottom';
import { Button } from 'react-bootstrap';




export class DashBoardGate extends Component {

      render() {

        return (
          <div>
            <div style={{"display" : "flex", "flexDirection" : "row", "alignItems" : "center", "height" : "calc(100vh - 220px)"}}>
              <div style={{"margin" : "auto", "color" : "purple","alignItems" : "center", "height" : "400px", "width" : "600px", "border" : "3px solid Purple", "padding" : "10px", "borderRadius" : "25px"}}>
                  <div style={{"margin" : "auto"}}><h1>Only Chapter President, Vice President, Treasurer, Secretary, and Ambassador can view the Union Intelligence Dashboard.</h1>
                  </div>
                  <div>
                  <Button variant="info" className="mx-2 btn-events" href="/reportembed" style={{"marginTop" : "20px"}}>Proceed to Dashboard</Button>
                  </div>
              </div>
            </div>
            <NavBottom />
            </div>
        );
    }
}

