import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'; 
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from "react-bootstrap";

export function RSVPUpcomingEventsMain(props) {

        const [data, setData] = useState(0);

/*         function register(eventID) {
            console.log(`Registering member ${props.memberID} for event ${eventID}`);

            (async () => {
                const result = await axios.post(`https://prod-52.westus.logic.azure.com/workflows/db71493270564ed19d57674acc60cebc/triggers/manual/paths/invoke/register?MemberID=${props.memberID}&EventID=${eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r1RHW-JN767bYHKn8yEoINy0tjTlktwWFSJRJ_sP5EA`);
                alert(result.data);
                })();
        } */

        // Using useEffect to call the API once mounted then set the state
        useEffect(() => {
            (async () => {
                //console.log(props.memberID);
            const result = await axios("https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk");
            setData(result.data);
            })();
        }, []);


        // Setup Table
        const columns = useMemo(
            () => [
            {
                //Table Header - Upcoming Events
                Header: "Upcoming Events",
                //Columns
                columns: [
                {
                    Header: "Event Name",
                    accessor: "Name"
                },
                {
                    Header: "Start Date",
                    accessor: "StartDate"
                    // accessor: d => {
                    //     return moment(d.StartDate)
                    //       .local().format("YYYY-MM-DD hh:mm:ss A")
                    // },
                    // sortType: "datetime",
                    // Cell: row => <span>
                    // {
                    //   moment(row.value).format('MM/DD/YYYY h:mm A')
                    // }
                    // </span>
                },
/*                 {
                    Header: "End Date",
                    accessor: "EndDate"
        
                },
                {
                    Header: "Event ID",
                    accessor: "EventID"
                }
                ,
                {
                    Header: "Register",
                    accessor: d => {
                        return <button onClick={() => register(d.EventID)} >Register</button>
                    }
                } */
                ]
            }
            ],
            []
        );

        return (
            <div>
                {
                    data
                    ? 
                    <div>
                    <Table columns={columns} data={data} />
                    <Button variant="info" className="mx-2 btn-events" href="/rsvpeventspage" style={{"marginTop" : "10px"}}>Go to Event Registration</Button>
                    </div>
                    :
                    <div className="div-loading" style={{"color" : "purple","display" : "flex", "alignItems" : "center", "width" : "100%"}}><Spinner animation="border" style={{"color" : "purple", "width" : "44px"}} /><h1>Fetching Event Data... </h1></div>
                }
            </div>
        );
}
